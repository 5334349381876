.content {
  --background: #f0f0f0;
}
.wrap {
  height: calc(100% - 50px - #{env(safe-area-inset-bottom)});
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 40px;
}

.sliderWrap {
  flex: 1;
  display: flex;

  flex-direction: column;
  justify-content: center;
}
.infoGrid {
  margin: 24px 40px 0 40px;
}
.row {
  background: white;
  border: 1px solid #6d6d6d;
  display: flex;

  & + .row {
    border-top: 0 none;
  }

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
}

.col {
  flex: 0 0 50%;
  padding: 14px 20px;
}

.slide {
  height: 370px;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.slideActive {
  z-index: 999;
}
.slideActive:not(.blank) {
  opacity: 1;
}

.img {
  flex: 0 0 230px;
  position: relative;
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.missed {
  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6d6d6d;
    &:before {
      content: "Missed Week, No Update";
      color: white;
      width: 60%;

      text-align: center;
    }
  }
}

.active {
  .img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.slideInner {
  background: white;
  border-radius: 5px;
  width: 210px;
  border: 1px solid #6d6d6d;
  height: 260px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.info {
  flex: 1;
  text-align: left;
  padding: 16px;
  h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
  }

  span {
    font-size: 16px;
    line-height: 20px;
  }
}

.feeling {
  position: absolute;
  font-size: 22px;
  bottom: 8px;
  right: 8px;
}

.button {
  --border-radius: 50px;
}
