@font-face {
  font-family: 'GTEesti';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/GT-Eesti-Pro-Display-Regular.ttf');
}

@font-face {
  font-family: 'GTEesti';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/GT-Eesti-Pro-Display-Regular-Italic.ttf');
}

@font-face {
  font-family: 'GTEesti';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/GT-Eesti-Display-Light.ttf');
}