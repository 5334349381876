.footer {
  .toolbar {
    --background: none;
    --border-width: 0 !important;
    --border-color: none;
    --padding-bottom: 36px;
    --padding-top: 16px;
    --padding-start: 16px;
    --padding-end: 16px;
    text-align: center;
    position: relative;
  }
}
