.bar {
  opacity: 0;
  position: relative;
  margin-top: 1.5em;
  width: calc(100% - env(safe-area-inset-bottom));
  --background: none;
  border-radius: 100px;
  --buffer-background: none;
  height: 16px;
}

.show {
  opacity: 1;
}
