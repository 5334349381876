.content {
  --background: #f0f0f0;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
    margin: 1.5em 0;
    text-align: center;
  }
}
.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 50px - #{env(safe-area-inset-bottom)});
}
.slideInner {
  background: white;
  border-radius: 5px;
  width: 277px;
  border: 1px solid #6d6d6d;
  min-height: 446px;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.02em;
    white-space: pre-line;
  }
}

.header {
  padding: 16px;
  border-bottom: 1px solid #6d6d6d;
  text-align: left;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  align-items: center;
}

.color {
  display: inline-block;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-right: 12px;
}

.description {
  padding: 14px;
  border-bottom: 1px solid #6d6d6d;
}

.instructions {
  padding: 16px;
  text-align: left;

  h5 {
    margin: 5px 0 16px 0;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.01em;
  }
}

.button {
  --border-radius: 50px;
  margin: auto 16px 16px;
}
