.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon {
  opacity: 0.5;
  display: inline-block;
  padding: 7px;
}

.selected {
  opacity: 1;
}

.button {
  --border-radius: 50px;
}
