.tabBar {
  --background: none;
  --border: none;
  letter-spacing: 0.2px;
}

:global(.tabs-inner) {
  position: unset !important;
  flex: 1;
}

.tabButton {
  --color: #2d2c2c;
  --color-selected: #6f9600;
}

.hasNotification {
  &:before {
    content: " ";
    display: inline-block;
    width: 6px;
    height: 6px;
    background: #6f9600;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 8px;
  }
}
