.content {
  --padding-start: 30px;
  --padding-end: 30px;
  --background: #6f9600;
}
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

  p {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
    margin: 1.5em auto;
    text-align: center;
    max-width: 265px;
  }
}

.icon {
  font-size: 42px;
}
.link {
  text-decoration: underline;
}
