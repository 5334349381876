.content {
  --background: #221f1f;
  --padding-start: 30px;
  --padding-end: 30px;

  text-align: center;
}

.header {
  // padding: 30px;
  font-size: 14px;
  --background: none;
  --padding-top: 30px;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-ned: 0;
  letter-spacing: 0.05em;
}
.image {
  max-height: 186px;
}
.preview {
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

  h3 {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.01em;
    margin: 0 0 8px 0;
  }

  p {
    margin: 0 0 24px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
  }
}

.product {
  background: #4b4b4b;
  padding: 19px;
  vertical-align: middle;
  width: 265px;
  text-align: left;
  border-radius: 4px 4px 0 0;

  & + .product {
    margin-top: 1px;
    border-radius: 0;

    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }
}

.color {
  display: inline-block;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-right: 12px;
  vertical-align: middle;
}

.button {
  --border-radius: 50px;
}

.review {
  color: white;
  margin: 0 auto;
  min-height: calc(100vh - 300px);
}

.notice {
  text-align: left;

  letter-spacing: 0.03em;
}

.productOverview {
  margin-bottom: 30px;
  .count {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
  }
}

.productCard {
  margin: 14px 0;
  border: 1px solid black;
  background: #4b4b4b;
  border-radius: 4px;

  .title {
    text-align: left;
    padding: 19px;
    border-bottom: 1px solid black;
  }

  .description {
    padding: 19px;

    p {
      margin: 0;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.02em;
    }
  }
}

.fieldset {
  text-align: left;
  margin-bottom: 45px;

  .label {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    display: inline-block;
    margin-bottom: 4px;
  }

  .input {
    width: 100%;
    background: white;
    margin-top: 8px;
    border: 1px solid #2d2c2c;
    border-radius: 8px;
    text-align: left;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 16px;
    --padding-end: 16px;
    --placeholder-color: black;
    --color: black;
    font-size: 16px;
    line-height: 22px;
  }
}

.footer {
  padding: 35px 0;
}

.error {
  color: #ff0000;
}
