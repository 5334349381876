.content {
  --padding-start: 30px;
  --padding-end: 30px;
  --background: #f3f1e9;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
  h2 {
    font-size: 28px;
    line-height: 32px;
    margin: 0 12px 30px;
    text-align: center;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: px;
    margin: 1.5em 0;
    text-align: center;
  }

  a {
    text-decoration: underline;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button {
  text-decoration: underline;
}
