.modal {
  --max-width: 85%;
  --max-height: 78%;
  --backdrop-opacity: 0.7 !important;
  --border-radius: 4px;
  --background-color: white;
  --background: white;
  --border-color: #6d6d6d;
  --border-width: 1px;
  --border-style: solid;

  :global(.sc-ion-modal-ios) {
    background-color: white;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  span:first-child {
    letter-spacing: 0.02em;
    font-weight: 500;
  }
  flex: 0 0 auto;
}

.image {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: black;
  background-position: center center;
  flex: 1;
  border-top: 1px solid #6d6d6d;
}

.emoji {
  position: absolute;
  display: inline-block;
  bottom: 16px;
  right: 16px;
  font-size: 36px;
}

.section {
  display: flex;
  flex: 0 0 auto;
  padding: 16px;
  border-top: 1px solid #6d6d6d;
}

.title {
  flex: 0 0 28%;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.content {
  flex: 0 0 72%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  p {
    margin: 0;
    white-space: pre-wrap;
  }
}

.product {
  display: flex;
  align-items: center;
}

.color {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}
