.button {
  --border-radius: 50px;
}

.content {
  --padding-start: 30px;
  --padding-end: 30px;
}

.security {
  padding-left: 28px;
  background: url("../../assets/images/security.png") no-repeat left center;
  background-size: 24px auto;
  width: 260px;
  margin: 0px auto 16px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
}

.form {
  margin-top: calc(100vh / 2 - 240px);
  h2 {
    font-size: 24px;
    line-height: 32px;
    margin: 30px 12px;
  }
}

.intro {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  h1 {
    font-size: 50px;
    line-height: 48px;
  }

  p {
    margin-top: 0;
    margin-bottom: 80px;
  }
}

.input {
  border: 1px solid black;
  border-radius: 4px;
  padding: 0 24px;
  line-height: 42px;
  // height: 65px;
  // flex: 0 0 auto;
  text-align: center;
  font-size: 20px;
  letter-spacing: 16px;
  --padding-end: 0;
}

:global {
  .PhoneInput {
    border: 1px solid black;
    padding: 0 24px;
    border-radius: 4px;
    background: white;
    --PhoneInputCountryFlag-height: 10px;
    --PhoneInput-color--focus: transparent;
    --PhoneInputCountryFlag-borderColor: transparent;
    font-size: 20px;
    .PhoneInputInput {
      padding: 0;
      border: 0 none;
      background: transparent;
      height: 65px;

      &:focus {
        outline: none;
      }
    }
  }
}
