.container {
  height: 100%;
}
.slide {
  flex-direction: column;
}

.icon {
  font-size: 42px;
}

.text {
  max-width: 265px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  p {
    margin: 1.5em 0;
  }
}

.button {
  --border-radius: 50px;
}
