.form {
  h2 {
    font-size: 28px;
    line-height: 32px;
    margin: 30px 12px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-align: center;
    width: 240px;
    margin: 29px auto;
  }
}

.button {
  --border-radius: 50px;
}

.terms {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 8px;
}

.link {
  text-decoration: underline;
  position: relative;
  z-index: 10;
}
.checkbox {
  font-size: 14px;
}

.modalContent {
  padding: 30px;
  position: relative;
  header {
    margin-top: 75px;
    margin-bottom: 55px;
    h2 {
      margin: 0 0 8px;
      font-size: 28px;
      line-height: 32px;
      letter-spacing: 0.01em;
    }
    p {
      margin: 0;
    }
  }

  p {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    margin: 1.5em 0;
  }
}

.close {
  position: absolute;
  top: 40px;
  right: 30px;
  font-weight: 500;
  font-size: 14px;
  z-index: 30;
}
.input {
  border: 1px solid black;
  border-radius: 4px;
  padding: 0 24px;
  line-height: 42px;
  text-align: center;
  font-size: 20px;
  --padding-end: 0;
}
