.content {
  --background: #f0f0f0;
  --padding-start: 40px;
  --padding-end: 40px;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;

  p {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
    margin: 1em 0;
    text-align: center;
  }

  h5 {
    font-size: 28px;
    line-height: 32px;
    margin: 0;
  }
}

.header {
  text-align: center;

  .icon {
    margin: 0;
  }

  h5 + .icon {
    margin: 24px 0;
  }

  .icon + h5 {
    margin-top: 16px;
  }
}

.icon {
  font-size: 42px;
  display: inline-block;
  margin: 24px 0;
}

.button {
  --border-radius: 50px;
  width: 100%;
  margin-top: 30px;
}

.infoGrid {
  margin: 24px 0 0 0;
}
.row {
  background: white;
  border: 1px solid #6d6d6d;
  display: flex;

  & + .row {
    border-top: 0 none;
  }

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
}

.col {
  flex: 0 0 50%;
  padding: 14px 20px;
}

.examplesLink {
  font-size: 16px;
  text-decoration: underline;
  margin: 24px 0 !important;

  padding: 0;
  display: block;
}

.examplesLink + .button {
  margin-top: 0px;
}

.modal {
  --background: white;
  --max-width: 296px;
  --max-height: 556px;
  --border-color: white;
  --border-radius: 4px;
  --border-width: 1px;
  --border-style: solid;
  --backdrop-opacity: 0.7;

  .inner {
    padding: 0 16px;
    justify-content: space-around;
  }
}

.modalContent {
  color: #221f1f;
  --background: white;
  font-size: 16px;
  line-height: 22px;

  letter-spacing: 0.02em;
}
.modalInner {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 30px;

  p {
    text-align: center;
    margin: 0.7em 0;
  }
  font-size: 16px;
  line-height: 22px;
  color: #221f1f;
  letter-spacing: 0.02em;
}
