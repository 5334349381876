.header {
  --background: none;
}

.title {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.06em;
  height: 44px;
  text-transform: none;
}

.light {
  --color: white;
}

.red {
  --color: #6f9600;
}
